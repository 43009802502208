import React, {useState} from 'react';
import logo from './logo.svg';

import './App.scss';

import "bootstrap/dist/js/bootstrap.bundle.min";

import {Alert, Button, Col, Row, Stack, Toast} from 'react-bootstrap';
import {
    AuthenticatedTemplate,
    MsalAuthenticationTemplate,
    UnauthenticatedTemplate
} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";


function App() {
    // @ts-ignore
    function ErrorComponent({error}) {
        return <p>An Error Occurred: {error}</p>;
    }

    function LoadingComponent() {
        return <p>Authentication in progress...</p>;
    }
    const authRequest = {
        scopes: ["openid", "profile"]
    };
    
    return (

        <div className="App">
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={LoadingComponent}>
                asdf
            </MsalAuthenticationTemplate>
            <AuthenticatedTemplate>
                <Alert  variant='success'>
                    <Row>
                        <Col>
                         <Row>   Login Success!</Row>
                        </Col>
                    </Row>  
                </Alert>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              
   
            </UnauthenticatedTemplate>
        </div>
    );
}

export default App;

// @ts-ignore


function BasicExample() {
    return (
        <>
            {[
                'primary',
                'secondary',
                'success',
                'danger',
                'warning',
                'info',
                'light',
                'dark',
            ].map((variant) => (
                <Alert key={variant} variant={variant}>
                    This is a {variant} alert—check it out!
                </Alert>
            ))}
        </>
    );
}

