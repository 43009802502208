import {Configuration} from "@azure/msal-browser";


export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_susi_reset_v2",
        editProfile: "b2c_1_edit_profile_v2"
    },
    authorities: {
        SignIn: {
            authority: "https://timelinebilling.b2clogin.com/timelinebilling.onmicrosoft.com/B2C_1_signin",
        },  
    },
    authorityDomain: "timelinebilling.b2clogin.com"
};

export const msalConfig: Configuration = {
    auth: {

        clientId: "2dce46e7-4508-4a30-875e-92eb51f3a606",
        redirectUri: "https://portal.timelinebilling.com/",
        authority: b2cPolicies.authorities.SignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
    }
};


